import { makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import tw, { css, styled } from 'twin.macro';

import { Text } from './Text';

type Props = TextFieldProps & {
  label: string;
  errorMessage?: string;
};

let useStyles = makeStyles((theme) => ({
  input: {
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
    },
  },
  root: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '95%',
    },
  },
}));

export let ErrorMessageContainer = styled.div`
  background-color: rgba(255, 140, 119, 1);
  height: fit-content;
  width: fit-content;
  padding: 8px;
  right: -134px;
`;

export default function TextInput({
  label,
  errorMessage,
  ...otherProps
}: Props) {
  let muiStyles = useStyles();
  let inputProps = useMemo(
    () => ({
      className: muiStyles.input,
    }),
    [muiStyles],
  );
  let [focus, setFocus] = useState(false);

  return (
    <div>
      <TextField
        {...otherProps}
        error={!!errorMessage}
        inputProps={inputProps}
        className={muiStyles.root}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        label={
          errorMessage ? (
            <div
              css={css`
                ${tw`flex justify-between items-center`}
              `}
            >
              <Text
                css={css`
                  ${tw`text-black`}
                  width: fit-content;
                  font-size: 24px;
                  line-height: 1 !important;
                `}
              >
                {label}
              </Text>
              {errorMessage ? (
                <ErrorMessageContainer
                  css={
                    otherProps.value || focus ? tw`opacity-100` : tw`opacity-0`
                  }
                >
                  <Text
                    css={tw`text-white lg:text-4xs lg:leading-10 font-medium`}
                  >
                    {errorMessage}
                  </Text>
                </ErrorMessageContainer>
              ) : null}
            </div>
          ) : (
            label
          )
        }
      />
    </div>
  );
}
